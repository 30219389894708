export const hasIconIndicator = (indicatorName: string) => {
  const indicatorNameLower = indicatorName.toLowerCase();
  return <T extends { name: string }>(labels: T[] | null = []) => {
    return labels?.some((icon) =>
      icon.name.toLowerCase().includes(indicatorNameLower),
    );
  };
};

export const hasDiscountIndicator = hasIconIndicator('discount');
export const hasIsNewIndicator = hasIconIndicator('isNew');
export const hasPopularityIndicator = hasIconIndicator('popularity');
export const hasGenderIndicator = hasIconIndicator('gender');
export const hasMraIndicator = hasIconIndicator('mra');
