export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type CompareFn<T> = (a: T, b: T, sortDirection: SortDirection) => number;

export interface SortParamsOfType<
  T extends string | number,
  D = SortDirection,
> {
  type: T;
  direction: D;
}

export type SelectorNumberFn<T> = (data: T) => number;
export type SelectorStringFn<T> = (data: T) => string;
