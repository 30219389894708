import { Configurations } from '../models/config.model';

export const NAMESPACE_PRODUCT_BASKET = 'productBasket';

export enum PBasketConfigurationKeys {
  ShowBulkAddListButton = 'showBulkAddListButton',
  ShowBulkAddListButtonRoles = 'showBulkAddListButtonRoles',
  BulkAddLimit = 'bulkAddLimit',
  DefaultPreset = 'default-preset',
  // Can be deleted after search v2 migration
  FilterColorsKey = 'filterColorsKey',
  FilterColors = 'filterColors',
  FilterIcons = 'filterIcons',
  FiltersOrder = 'filtersOrder',
  // this will override filter.collapse?.enabled
  FiltersOpenedIds = 'filtersOpenedIds',
  IsExportProductsAllowed = 'is-export-products-allowed',
  GroupByVariantAvailable = 'group-by-variant-available',
  DefaultGroupStyleVariants = 'default-group-style-variants',
  ShowLayoutSwitcher = 'show-pbasket-layout-switcher',
  HideEmptyFilterValues = 'hide-empty-filter-values',
  HiddenFilters = 'hidden-filters',
  HiddenFilterTitles = 'hidden-filter-titles',
  ResetPresetOnLogoClick = 'reset-preset-on-logo-click',
  ShowEmptyFilterOptions = 'show-empty-filter-options',
  ISDSelectableDaysInThePast = 'isd-selectable-days-in-the-past',
  FilterPillsSetting = 'filter-pills-setting',
  DefaultSortingConfig = 'default-sorting-config',
  FilterShortcutConfig = 'filter-shortcut-config',
  ExpandingProductCard = 'expanding-product-card',
  ExpandingUnavailableProductCard = 'expanding-unavailable-product-card',
  MultiRangeSliderInputs = 'multi-range-slider-inputs',
  ShowMediaRelations = 'show-media-relations',
  ExpandingCardAttributes = 'expanding-card-attributes',
  StackByFilters = 'stack-by-filters',
}

export interface PBasketConfigurations
  extends Configurations<PBasketConfigurationKeys> {
  /**
   * Whether to show the bulk add to list button
   */
  [PBasketConfigurationKeys.ShowBulkAddListButton]: boolean;
  [PBasketConfigurationKeys.DefaultPreset]: string;
  [PBasketConfigurationKeys.BulkAddLimit]: number;
  [PBasketConfigurationKeys.FilterColors]: string;
  [PBasketConfigurationKeys.FilterIcons]: string;
  [PBasketConfigurationKeys.FilterColorsKey]: string;
  [PBasketConfigurationKeys.FiltersOrder]: string[];
  [PBasketConfigurationKeys.FiltersOpenedIds]: string[];
  [PBasketConfigurationKeys.IsExportProductsAllowed]: boolean | string;
  [PBasketConfigurationKeys.GroupByVariantAvailable]: boolean;
  [PBasketConfigurationKeys.DefaultGroupStyleVariants]: boolean;
  [PBasketConfigurationKeys.ShowLayoutSwitcher]: boolean;
  [PBasketConfigurationKeys.HideEmptyFilterValues]: boolean;
  [PBasketConfigurationKeys.HiddenFilters]: string[];
  [PBasketConfigurationKeys.HiddenFilterTitles]: string[];
  [PBasketConfigurationKeys.ResetPresetOnLogoClick]: boolean;
  [PBasketConfigurationKeys.ShowEmptyFilterOptions]: boolean;
  [PBasketConfigurationKeys.ISDSelectableDaysInThePast]: number;
  [PBasketConfigurationKeys.FilterPillsSetting]: string;
  [PBasketConfigurationKeys.DefaultSortingConfig]: string;
  [PBasketConfigurationKeys.ExpandingProductCard]: string;
  [PBasketConfigurationKeys.ShowMediaRelations]: string;
  [PBasketConfigurationKeys.ExpandingUnavailableProductCard]: boolean;
  [PBasketConfigurationKeys.ExpandingCardAttributes]: string;
  [PBasketConfigurationKeys.StackByFilters]: string;
}

export const pBasketConfigurationsDefaultValues = {
  [PBasketConfigurationKeys.FilterIcons]: `{"category":{
    "Accessories":"category-accessories","Blazers":"category-blazers","Blouses":"category-blouses","Bodywear":"category-bodywear","Coats":"category-coats","Dresses":"category-dresses","Indoor Jackets":"category-jackets","Jackets":"category-jackets","Jeans":"category-jeans","Jumpsuits":"category-jumpsuit","Nightwear":"category-nightwear","Pants":"category-pants","Polo shirts":"category-polo","Shirts":"category-shirts","Shorts":"category-shorts","Skirts":"category-skirts","Sweaters":"category-sweaters","Sweatshirts":"category-sweatshirts","Swimwear":"category-swimwear","T-Shirts":"category-shirts", "default": "category-placeholder"
  }}`,
  [PBasketConfigurationKeys.FilterColorsKey]: 'color',
  [PBasketConfigurationKeys.FiltersOrder]: [],
  [PBasketConfigurationKeys.FiltersOpenedIds]: [],
  [PBasketConfigurationKeys.BulkAddLimit]: -1,
  [PBasketConfigurationKeys.ShowEmptyFilterOptions]: false,
  [PBasketConfigurationKeys.FilterColors]: `{"BEIGE":"#E1C699","BLACK":"#000000","BLUE":"#63C5E3","BROWN":"#8F5D5D","GREY":"#D0D0D0","GREEN":"#37BE5F","ORANGE":"#E18518","PINK":"#D168B4","PURPLE":"#B793DB","RED":"#DB575F","SILVER":"#D0D0D0","WHITE":"#FFFFFF","YELLOW":"#F3E883","default":"linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(99,197,227,1) 25%, rgba(253,80,43,1) 50%, rgba(243,232,131,1) 75%, rgba(55,190,95,1) 100%)"}`,
  [PBasketConfigurationKeys.GroupByVariantAvailable]: false,
  [PBasketConfigurationKeys.ISDSelectableDaysInThePast]: 0,
  [PBasketConfigurationKeys.FilterPillsSetting]: '[]',
  [PBasketConfigurationKeys.IsExportProductsAllowed]: false,
  [PBasketConfigurationKeys.DefaultSortingConfig]:
    '{"field": "Default", "direction": "Desc"}',
  [PBasketConfigurationKeys.ExpandingProductCard]: '',
  [PBasketConfigurationKeys.ExpandingUnavailableProductCard]: false,
  [PBasketConfigurationKeys.HiddenFilters]: [],
  [PBasketConfigurationKeys.HiddenFilterTitles]: [],
  [PBasketConfigurationKeys.MultiRangeSliderInputs]: false,
  [PBasketConfigurationKeys.ShowMediaRelations]: '',
  [PBasketConfigurationKeys.ExpandingCardAttributes]: '',
  [PBasketConfigurationKeys.StackByFilters]: '',
};

export const maxFilterPillsSize = 3;

export interface FilterPillSetting {
  fieldId: string;
  showPillLabel: boolean;
  fullPillText: boolean;
}

export interface ExpandProductCardSettings {
  active: boolean;
  content: ('info' | 'panel' | 'image')[];
  randomizeContent: boolean;
  footerButtonAttributes: string[];
}
