import { DateInput, DateTime, DurationInput, Interval } from 'luxon';

import { isNonNullish } from './nullish-check';
import { isString } from './type-guards';
import { utcDateTime } from './utc/utc-date-time';

/**
 * A date in the future which can be used as an indicator of infinite ranges
 */
export const MAX_DATE: Readonly<Date> = new Date(2200, 0, 0);
export const MIN_DATE: Readonly<Date> = new Date(1980, 0, 0);

// tslint:disable:no-identical-functions

/**
 * Returns the last day of the month
 * @param date a date
 */
export function lastDayOfMonth(date: Date): number {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  return new Date(year, month + 1, 0).getDate();
}

export function formattedDateTime(date: Date, format: string): string {
  return DateTime.fromJSDate(date).toLocal().toFormat(format);
}

/**
 * Returns the ISO date string
 * @param date a date
 */
export function getISODate(date: Date | string): string {
  if (typeof date === 'string') {
    return DateTime.fromJSDate(new Date(date)).toISODate();
  }

  return DateTime.fromJSDate(date).toISODate();
}

export function makeInterval(
  start: string,
  end: string = MAX_DATE.toISOString(),
): Interval {
  return Interval.fromDateTimes(utcDateTime(start), utcDateTime(end));
}

export function makeIntervalFromDates(
  start: DateInput,
  end: DateInput,
): Interval {
  return Interval.fromDateTimes(start, end);
}

export function parseISOInterval(stringISO: string) {
  const { start, end } = Interval.fromISO(stringISO);

  return [start && start.toJSDate(), end && end.toJSDate()];
}

export function getISOInterval(interval: Interval): string {
  return interval.toISODate();
}

// it returns null when intervals do not have intersection, otherwise it's resultive interval
export function intersectIntervals(intervals: Interval[][]): Interval[] {
  if (intervals.length > 2) {
    const [first, second, ...rest] = intervals;
    const intersection = intersectIntervals([first, second]);
    if (!intersection.length) {
      return [];
    }
    return intersectIntervals([intersection, ...rest]);
  }

  if (intervals.length === 2) {
    const [a, b] = intervals;

    return Interval.merge(
      a.flatMap((intervalA) =>
        b
          .map((intervalB) => intervalA.intersection(intervalB))
          .filter(isNonNullish),
      ),
    );
  }

  if (!intervals.length) {
    return [];
  }

  // there is only one element in array
  return intervals[0];
}

/**
 * Returns the date object set to the first day of the month
 * @param date a date
 */
export function firstDayOfMonthDate(date: Date) {
  return DateTime.fromJSDate(date).startOf('month').toJSDate();
}

/**
 * Returns the date object set to the last day of the month
 * @param date a date
 */
export function lastDayOfMonthDate(date: Date) {
  return DateTime.fromJSDate(date).endOf('month').toJSDate();
}

export function lastDayOfMonthUTC(date: Date | string) {
  return utcDateTime(date).endOf('month').toISO({
    suppressMilliseconds: true,
  });
}

export function addMonth(date: Date): Date {
  return DateTime.fromJSDate(date).plus({ months: 1 }).toJSDate();
}

export function minusMonth(date: Date): Date {
  return DateTime.fromJSDate(date).minus({ months: 1 }).toJSDate();
}

export function addDays(date: Date, days: number = 1): Date {
  return DateTime.fromJSDate(date).plus({ days }).toJSDate();
}

/**
 * Returns the date object set to the start of the day
 * @param date
 */
export function startOfTheDayDate(date: string): string {
  return DateTime.fromISO(date, { setZone: true }).startOf('day').toISO();
}

export function startOfTheDay(date: Date): string {
  return DateTime.fromJSDate(date).startOf('day').toISO();
}

export function startOfDayDate(date: Date | string): DateTime {
  if (typeof date === 'string') {
    return DateTime.fromISO(date).startOf('day');
  }
  return DateTime.fromJSDate(date).startOf('day');
}

/**
 * Returns the date object set to the end of the day
 * @param date
 */
export function endOfTheDayDate(date: string): string {
  return DateTime.fromISO(date, { setZone: true }).endOf('day').toISO();
}

/**
 * Get if the first date is after the second one
 */
export function isAfter(first: Date, second: Date) {
  return first.getTime() > second.getTime();
}

export function getDatesDiff(start: Date, end: Date) {
  return DateTime.fromJSDate(end).diff(DateTime.fromJSDate(start));
}

export function getDatesDaysDiff(start: Date, end: Date) {
  return DateTime.fromJSDate(end)
    .set({ hour: 24, minute: 0 })
    .diff(DateTime.fromJSDate(start).set({ hour: 0, minute: 0 }), [
      'days',
      'hours',
    ]);
}

export function getDateOptions({ year, month, day }: DateTime) {
  return {
    year,
    month,
    day,
  };
}

/**
 * Get if the first date is before the second one
 */
export function isBefore(first: Date, second: Date) {
  return first.getTime() < second.getTime();
}

export function isStringDateBefore(first: string, second: string) {
  return DateTime.fromISO(first).ordinal < DateTime.fromISO(second).ordinal;
}

export function datesEqual(first: Date, second: Date) {
  return DateTime.fromJSDate(first).equals(DateTime.fromJSDate(second));
}

export function daysEqual(first: Date, second: Date) {
  return (
    DateTime.fromJSDate(first).ordinal === DateTime.fromJSDate(second).ordinal
  );
}

export function yearsEqual(first: Date, second: Date) {
  return DateTime.fromJSDate(first).year === DateTime.fromJSDate(second).year;
}

export function stringDaysEqual(first: string, second: string) {
  return DateTime.fromISO(first).ordinal === DateTime.fromISO(second).ordinal;
}

export function stringDatesEqual(first: string, second: string) {
  return DateTime.fromISO(first).equals(DateTime.fromISO(second));
}

export function getMinDate(first: Date, second: Date): Date {
  return isBefore(first, second) ? first : second;
}

/**
 * Return is the date happens to be in the same current month
 */
export function isCurrentMonth(date: Date) {
  const now = new Date();

  return (
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  );
}

export function isCurrentDay(date: Date) {
  const now = new Date();

  return (
    now.getDay() === date.getDay() &&
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear()
  );
}

export function getCurrentTimeZone() {
  return DateTime.local().zoneName;
}

export function isInDateRange(target: Date, start: Date, end: Date) {
  return makeIntervalFromDates(start, end).contains(
    DateTime.fromJSDate(target),
  );
}

export function formatDateToLocaleDate(
  date: Date | string,
  locale: string,
  format: string,
) {
  const dateValue = isString(date) ? new Date(date) : date;
  return DateTime.fromJSDate(dateValue).setLocale(locale).toFormat(format);
}

export function formatDateToLocaleDateUtc(
  date: Date | string,
  locale: string,
  format: string,
) {
  const dateValue = isString(date) ? new Date(date) : date;
  return DateTime.fromJSDate(dateValue, { zone: 'utc' })
    .setLocale(locale)
    .toFormat(format);
}

export function firstDayOfWeek(date: Date) {
  return DateTime.fromJSDate(date).startOf('week').toJSDate();
}

function firstDayOfWeekInUTC(date: Date) {
  return DateTime.fromJSDate(date, { zone: 'utc' }).startOf('week').toJSDate();
}

export function lastDayOfWeek(date: Date) {
  return DateTime.fromJSDate(date).endOf('week').toJSDate();
}

export function firstDayOfNextWeek(date: Date) {
  return DateTime.fromJSDate(date, { zone: 'utc' })
    .plus({ weeks: 1 })
    .startOf('week')
    .toJSDate();
}

export function firstDayOfNextWeekUTC(date: Date) {
  return DateTime.fromJSDate(date, { zone: 'utc' })
    .plus({ weeks: 1 })
    .startOf('week')
    .toISO({
      suppressMilliseconds: true,
    });
}

export function getFirstAvailableWeek(date: Date) {
  const todayWeek = firstDayOfWeekInUTC(new Date());
  const targetWeek = firstDayOfWeekInUTC(date);

  if (todayWeek.toJSON() === targetWeek.toJSON()) {
    return firstDayOfNextWeek(date);
  } else {
    return targetWeek;
  }
}

export function getWeekNumberInTheYear(
  year: number,
  month: number,
  day: number,
): number {
  return DateTime.local(year, month, day).weekNumber;
}

export function getLastWeekNumberInTheYear(year: number): number {
  const lastMonth = 12;
  const lastDayNumber = 31;
  let weekNumber = getWeekNumberInTheYear(year, lastMonth, lastDayNumber);
  let dayMinus = 1;
  while (weekNumber === 1) {
    weekNumber = getWeekNumberInTheYear(
      year,
      lastMonth,
      lastDayNumber - dayMinus,
    );
    dayMinus += 7;
  }
  return weekNumber;
}

// we need length param due to the fact, that the last week, for instance, of 2020 would
// be 53d week in 2020, but the next one would be 1 week of 2021
export function getWeeksFromDate(
  length: number,
  year: number,
  month: number,
  day: number,
): { week: number; year: number }[] {
  return Array.from({ length }).map((_, i) => {
    return getWeekNumberAndYear(new Date(year, month - 1, day + i * 7));
  });
}

/**
 * returns the last working day of the week
 * @param Date The first date of the week
 */
export function lastWorkDayOfWeek(date: Date) {
  return DateTime.fromJSDate(date).endOf('week').plus({ days: -2 }).toJSDate();
}

export function dateTimeNow() {
  return DateTime.now();
}

export function getCurrentYear() {
  return dateTimeNow().year;
}

export function dateMinus(start: Date, durationInput: DurationInput) {
  return DateTime.fromJSDate(start).minus(durationInput).toJSDate();
}

export function datePlus(start: Date, durationInput: DurationInput) {
  return DateTime.fromJSDate(start).minus(durationInput).toJSDate();
}

export function getWeekNumberAndYear(dateParam: Date) {
  const date = new Date(dateParam);
  // Set the target date to Monday of the given week to ensure consistency
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));
  // Get the year of the target date
  const year = date.getFullYear();
  // Get the start date of the year
  const yearStart = new Date(year, 0, 1);
  // Calculate the week number
  const week = Math.ceil(
    ((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
  );
  return { week, year };
}
