import { createFeatureSelector, createSelector } from '@ngrx/store';

import { i18nFeatureKey, LanguageState } from './i18n.reducer';

/** Language feature selector */
const selectLanguageState =
  createFeatureSelector<LanguageState>(i18nFeatureKey);

/** Selector to get the selected language */
export const selectLanguage = createSelector(
  selectLanguageState,
  (state: LanguageState) => state.selected,
);
/** Selector to get the available languages */
export const selectAvailableLanguages = createSelector(
  selectLanguageState,
  (state: LanguageState) => state.availables,
);

export const selectCurrentLanguage = createSelector(
  selectLanguage,
  selectAvailableLanguages,
  (selected, languages) =>
    languages?.find((language) => language.id === selected),
);

export const selectCurrentDateFormat = createSelector(
  selectCurrentLanguage,
  (language) => language?.dateFormat ?? 'dd.MM.yyyy',
);
