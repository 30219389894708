import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Configurations } from '../models/config.model';
import { mobileOnlyFeature } from '../utilities';

export const maxUnlimitedQuantityOrder = 99999;
export const MAX_DISPLAYED_INVENTORY = 100;

export const enum ProductIndicator {
  Popularity = 'popular',
  Sustainable = 'sustainable',
  NeverOutOfStock = 'noos',
  NewByDeliveryMonth = 'new-by-delivery-month',
  MarketingSupport = 'marketing-support',
  Gender = 'gender',
}

export const enum NoosIconType {
  Regular = 'regular',
  Advanced = 'advanced',
}

export const TITLE = 'title';
export const COLOR_CODE = 'attributes.colorcode';
export const COLOR_DESCRIPTION = 'attributes.colordescription';

/** responsible both for components (re-usable) and route (config strip feature) */
export const CONFIG_STRIP_FEATURE = 'config-strip';

/**
 * configuration keys for this module
 */
export enum ConfigStripConfigurationKeys {
  ShowPrepackNote = 'show-prepack-note',
  ShowPrepackPrices = 'show-prepack-prices',
  ImageDownloadEnabled = 'image-download-enabled',
  ImageDownloadWebpDisabled = 'image-download-webp-disabled',
  Indicators = 'indicators',
  MaterialTitleAttributes = 'material-title-attributes',
  showUnlimitedWeekInventories = 'show-unlimited-week-inventories',
  ShowDatePicker = 'show-date-picker',
  TitleFormat = 'title-format',
  ShowThumbnails = 'show-thumbnails',
  ScaleSetTranslation = 'scale-set-translation',
  CellTotalsFilter = 'cell-totals-filter',
  AvailabilityRanges = 'availability-ranges',
  HideOnlyNowInventoryLogistic = 'hide-only-now-inventory-logistic',
  InventoriesOverviewMaxShownInventories = 'inventory-overview-max-show-inventory-number',
  ShowInfinitySign = 'show-infinity-sign',
  ShowBackOrderFactory = 'show-barkorder-factory',
  ShowNextAvailableDate = 'show-next-available-date',
  ShowGridLegend = 'show-grid-legend',
  ShowInventoryExternalIdAsCustomLabel = 'show-inventory-external-id-as-custom-label',
  NoosIconsType = 'noos-icons-type',
  ShowDisplayModeToggle = 'show-display-mode-toggle',
  ShowProductInfoAsText = 'show-product-info-as-text',
  SingleSizeCopyIcon = 'single-size-copy-icon',
  MultipleSizeCopyIcon = 'multiple-size-copy-icon',
  DeliverySegmentDateIcon = 'delivery-segment-date-icon',
  CopySizeRunEnabled = 'copy-size-run-enabled',
  UseNewShopsView = 'use-new-shops-view',
  MaxDisplayedInventory = 'max-displayed-inventory',
  MaxUnlimitedQuantityOrder = 'max-unlimited-quantity-order',
  MultipleShopsSelectorView = 'multiple-shops-selector-view',
  CollapsibleInventoryOverview = 'collapsible-inventory-overview',
  CollapsableInventoriesOverviewMinQuantity = 'collapsable-inventories-overview-min-quantity',
  // Defines the maximum number of inventories that can be shown in the inventory overview, if not defined the default value is 0 and count depends on screen width
  MaxVisibleInventories = 'max-visible-inventories',
  LoadCombinedCallPrices = 'load-combined-call-prices',
  KeepEmptyScales = 'keep-empty-scales',
  PasteFromBuffer = 'paste-from-buffer',
  CopyFromGrid = 'copy-from-grid',
  DifferentMaterialDimensions = 'different-material-dimensions',
  DisableOnBasketUpdate = 'disable-on-basket-update',
  ShowVariantsQuickLook = 'show-variants-quick-look',
  ShowBookmarkButton = 'show-bookmark-button',
  ShowSizeGridBookmarkButton = 'show-size-grid-bookmark-button',
  ShowPresetNowDatePicker = 'show-preset-now-date-picker',
  SplitInventoriesOverviewForSameDateEnabled = 'split-inventories-overview-for-same-date-enabled',
  PresetNowDatePickerAheadConfig = 'preset-now-date-picker-ahead-config',
  // used together with InventoriesOverviewMaxShownInventories and MaxUnlimitedQuantityOrder and defines the MaxUnlimitedQuantityOrder has more priority in inventory overview label and grid cell placeholder
  // so if this is true, the MaxUnlimitedQuantityOrder will be shown in the inventory overview label and grid cell placeholder
  PrioritizeMaxUnlimitedQuantityShown = 'prioritize-max-unlimited-quantity-shown',
  CacheExpandedProductDetails = 'cache-expanded-product-details',
  ShowTotalPriceInConfigStripButton = 'show-total-price-in-config-strip-button',
  HideInventoryOverviews = 'hide-inventory-overviews',
  HideInventoryCellTotals = 'hide-inventory-cell-totals',
  InventoryColorMapper = 'inventory-color-mapper',
  TrueDemandEnabled = 'true-demand-enabled',
}

/**
 * Configuration return types and default values for this module
 */
export interface ConfigStripConfigurations
  extends Configurations<ConfigStripConfigurationKeys> {
  /**
   * Whether to show the prepack note input∏
   */
  [ConfigStripConfigurationKeys.ShowPrepackNote]: boolean;
  [ConfigStripConfigurationKeys.ShowPrepackPrices]: boolean;
  [ConfigStripConfigurationKeys.ImageDownloadEnabled]: boolean;
  [ConfigStripConfigurationKeys.ImageDownloadWebpDisabled]: boolean;
  [ConfigStripConfigurationKeys.Indicators]: ProductIndicator[];
  [ConfigStripConfigurationKeys.MaxDisplayedInventory]: number;
  [ConfigStripConfigurationKeys.MaxUnlimitedQuantityOrder]: number;
  [ConfigStripConfigurationKeys.MaterialTitleAttributes]: string[];
  [ConfigStripConfigurationKeys.ShowDatePicker]: boolean;
  [ConfigStripConfigurationKeys.ScaleSetTranslation]: boolean;
  [ConfigStripConfigurationKeys.ShowThumbnails]: boolean;
  [ConfigStripConfigurationKeys.CellTotalsFilter]: string;
  [ConfigStripConfigurationKeys.AvailabilityRanges]: string;
  [ConfigStripConfigurationKeys.InventoriesOverviewMaxShownInventories]: number;
  [ConfigStripConfigurationKeys.CollapsableInventoriesOverviewMinQuantity]: number;
  [ConfigStripConfigurationKeys.MaxVisibleInventories]: number;
  [ConfigStripConfigurationKeys.HideOnlyNowInventoryLogistic]: boolean;
  [ConfigStripConfigurationKeys.ShowInfinitySign]: boolean;
  [ConfigStripConfigurationKeys.ShowGridLegend]: boolean;
  [ConfigStripConfigurationKeys.NoosIconsType]: NoosIconType;
  [ConfigStripConfigurationKeys.ShowDisplayModeToggle]: boolean;
  [ConfigStripConfigurationKeys.ShowProductInfoAsText]: boolean;
  [ConfigStripConfigurationKeys.SingleSizeCopyIcon]: string[];
  [ConfigStripConfigurationKeys.MultipleSizeCopyIcon]: string[];
  [ConfigStripConfigurationKeys.DeliverySegmentDateIcon]: string[];
  [ConfigStripConfigurationKeys.CopySizeRunEnabled]: boolean;
  [ConfigStripConfigurationKeys.UseNewShopsView]: boolean;
  [ConfigStripConfigurationKeys.MultipleShopsSelectorView]: boolean;
  [ConfigStripConfigurationKeys.CollapsibleInventoryOverview]: boolean;
  [ConfigStripConfigurationKeys.LoadCombinedCallPrices]: boolean;
  [ConfigStripConfigurationKeys.KeepEmptyScales]: boolean;
  [ConfigStripConfigurationKeys.PasteFromBuffer]: boolean;
  [ConfigStripConfigurationKeys.CopyFromGrid]: boolean;
  [ConfigStripConfigurationKeys.DifferentMaterialDimensions]: boolean;
  [ConfigStripConfigurationKeys.DisableOnBasketUpdate]: boolean;
  [ConfigStripConfigurationKeys.ShowVariantsQuickLook]: boolean;
  [ConfigStripConfigurationKeys.ShowBookmarkButton]: boolean;
  [ConfigStripConfigurationKeys.ShowSizeGridBookmarkButton]: boolean;
  [ConfigStripConfigurationKeys.ShowPresetNowDatePicker]: boolean;
  [ConfigStripConfigurationKeys.SplitInventoriesOverviewForSameDateEnabled]: boolean;
  [ConfigStripConfigurationKeys.PresetNowDatePickerAheadConfig]: string;
  [ConfigStripConfigurationKeys.PrioritizeMaxUnlimitedQuantityShown]: boolean;
  [ConfigStripConfigurationKeys.CacheExpandedProductDetails]: boolean;
  [ConfigStripConfigurationKeys.ShowTotalPriceInConfigStripButton]: boolean;
  [ConfigStripConfigurationKeys.HideInventoryOverviews]: boolean;
  [ConfigStripConfigurationKeys.HideInventoryCellTotals]: boolean;
  [ConfigStripConfigurationKeys.InventoryColorMapper]: string;
  [ConfigStripConfigurationKeys.TrueDemandEnabled]: boolean;
}

export const configStripConfigurationDefaultValues = {
  [ConfigStripConfigurationKeys.AvailabilityRanges]: '[]',
  [ConfigStripConfigurationKeys.UseNewShopsView]: false,
  [ConfigStripConfigurationKeys.ImageDownloadWebpDisabled]: false,
  [ConfigStripConfigurationKeys.MaxDisplayedInventory]: MAX_DISPLAYED_INVENTORY,
  [ConfigStripConfigurationKeys.MaxUnlimitedQuantityOrder]:
    maxUnlimitedQuantityOrder,
  [ConfigStripConfigurationKeys.MultipleShopsSelectorView]: false,
  [ConfigStripConfigurationKeys.ShowDisplayModeToggle]: false,
  [ConfigStripConfigurationKeys.ShowBackOrderFactory]: false,
  [ConfigStripConfigurationKeys.ShowInfinitySign]: true,
  [ConfigStripConfigurationKeys.ShowGridLegend]: false,
  [ConfigStripConfigurationKeys.HideOnlyNowInventoryLogistic]: false,
  [ConfigStripConfigurationKeys.LoadCombinedCallPrices]: true,
  [ConfigStripConfigurationKeys.KeepEmptyScales]: false,
  [ConfigStripConfigurationKeys.PasteFromBuffer]: false,
  [ConfigStripConfigurationKeys.CellTotalsFilter]: '',
  [ConfigStripConfigurationKeys.CopyFromGrid]: false,
  [ConfigStripConfigurationKeys.MaterialTitleAttributes]: [
    TITLE,
    COLOR_CODE,
    COLOR_DESCRIPTION,
  ],
  [ConfigStripConfigurationKeys.DifferentMaterialDimensions]: false,
  [ConfigStripConfigurationKeys.DisableOnBasketUpdate]: false,
  [ConfigStripConfigurationKeys.ShowVariantsQuickLook]: false,
  [ConfigStripConfigurationKeys.ShowBookmarkButton]: false,
  [ConfigStripConfigurationKeys.ShowProductInfoAsText]: false,
  [ConfigStripConfigurationKeys.ShowSizeGridBookmarkButton]: false,
  [ConfigStripConfigurationKeys.ShowPrepackPrices]: false,
  [ConfigStripConfigurationKeys.ShowPresetNowDatePicker]: false,
  [ConfigStripConfigurationKeys.ShowInventoryExternalIdAsCustomLabel]: false,
  [ConfigStripConfigurationKeys.SplitInventoriesOverviewForSameDateEnabled]:
    false,
  [ConfigStripConfigurationKeys.PresetNowDatePickerAheadConfig]: JSON.stringify(
    { weeks: 3 },
  ),
  [ConfigStripConfigurationKeys.PrioritizeMaxUnlimitedQuantityShown]: false,
  [ConfigStripConfigurationKeys.CacheExpandedProductDetails]: false,
  [ConfigStripConfigurationKeys.ShowTotalPriceInConfigStripButton]: false,
  [ConfigStripConfigurationKeys.HideInventoryOverviews]: false,
  [ConfigStripConfigurationKeys.HideInventoryCellTotals]: false,
  [ConfigStripConfigurationKeys.InventoryColorMapper]: '',
  [ConfigStripConfigurationKeys.TrueDemandEnabled]: true,
};

export const useNewShopsViewFn$ = (
  isMobile$: Observable<boolean>,
  useNewShopView$: Observable<
    ConfigStripConfigurations[ConfigStripConfigurationKeys.UseNewShopsView]
  >,
) =>
  combineLatest([isMobile$, useNewShopView$]).pipe(
    map(([isMobile, useNewShopView]) =>
      mobileOnlyFeature(isMobile, useNewShopView),
    ),
    startWith(true),
  );
